@import "react-phone-number-input/style.css";
@import "@saas-repo/shared-lib/src/scss/variables";
@import "@saas-repo/shared-lib/src/scss/colors";
@import "@saas-repo/shared-lib/src/scss/buttons";
@import "@saas-repo/shared-lib/src/scss/common";
@import "@saas-repo/shared-lib/src/scss/menu";
@import "@saas-repo/shared-lib/src/scss/spinner";
@import "@saas-repo/shared-lib/src/scss/footer";
@import "navbar";
@import "registration";
