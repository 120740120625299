.registration {
  &-wizard-step {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h2 {
      color: $color_fonts;
      font-weight: 400;
      line-height: 1.3;
    }
  }
  &-help {
    font-size: 12px;
    color: $purple_0;
    line-height: 1.3;
    display: flex;
  }
}

.not-found {
  background-color: $common_white;
  border-radius: 8px;
  opacity: 0.95;
  max-height: 500px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.success-register {
  background-color: $common_white;
  border-radius: 8px;
  opacity: 0.95;
  max-height: 500px;
  overflow-y: auto;
  margin-bottom: 20px;
  max-width: 650px;
}
