.navbar-brand {
  margin-right: 0px;
}
.line-vertical {
  height: 53px;
  padding-left: 75px;
  padding-right: 20px;
  margin-bottom: -9px;
  .vr {
    height: 100%;
    width: 1px;
    color: $common_white !important;
    opacity: inherit;
  }
}
.nav-item {
  color: $color_fonts;
}

.navbar-expand {
  .navbar-nav {
    padding-top: 10px;
    flex-wrap: wrap;
    .nav-link {
      padding-left: 7px;
      padding-right: 0px;
      padding: 2px 0px 2px 7px;
      color: $common_white;
      display: inline-flex;
      line-height: 1.2;
      white-space: nowrap;
      text-transform: capitalize;
      &:before {
        content: "|   ";
        display: inline;
        padding-right: 7px;
      }
      &:first-child {
        padding-left: 3px;
        &:before {
          content: "";
          display: inline;
        }
      }
    }
  }
}

.navbar-expand .navbar-nav .nav-link:before {
  content: "|";
  display: inline;
}
.nav-header {
  .navbar-collapse {
    .navbar-nav {
      flex-shrink: 0;
    }
  }
}

.navbar-nav > .nav-link:last-child {
  font-weight: 700;
  &::before {
    font-weight: 400;
  }
}
